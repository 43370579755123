<template>
  <div>
    <eden-page-header
      :title="'Service Partners'"
      :subtitle="'Add Service Partner'"
    />
    <service-partner-form action="add" />
  </div>
</template>

<script>
import ServicePartnerForm from "@/components/ServiceOperations/ServicePartners/ServicePartnerForm";

export default {
  name: "ServicePartnerAdd",
  components: {
    ServicePartnerForm,
  },
  data() {
    return {};
  },
};
</script>
