<template>
  <div v-loading="loading" class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <eden-form-section>
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="flex-wrap mb-0">
            <el-col :md="24" class="is-flex is-flex-column is-align-center">
              <eden-image-upload :image-url.sync="form.avatar" />
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Basic Information"
        subtitle="Let’s get to know this service partner"
      >
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Service Partner name"
                prop="organization_name"
                :rules="validateField()"
              >
                <el-input
                  type="text"
                  v-model="form.organization_name"
                  placeholder="Enter name"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="flex-wrap">
            <el-col :md="24">
              <el-form-item label="Email" prop="email" :rules="validateEmail()">
                <el-input
                  type="text"
                  v-model="form.email"
                  placeholder="you@mail.com"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Phone Number"
                prop="phone_no"
                :rules="validateField()"
              >
                <eden-phone-input :input.sync="form.phone_no" />
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Service Information"
        subtitle="Information about the services rendered by this Service Partner"
      >
        <template slot="form-fields">
          <el-row type="flex" class="flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Services"
                prop="service"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.service"
                  placeholder="Select service for this Service Partner"
                >
                  <el-option
                    v-for="(service, index) in services"
                    :key="index"
                    :label="service.service_name"
                    :value="service.slug"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Address"
                prop="address"
                :rules="validateField()"
              >
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="form.address"
                  placeholder="Where is this service partner located?"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions">
            <el-button plain @click="goToSpPage">Cancel</el-button>
            <el-button
              v-if="action === 'add'"
              type="primary"
              :loading="adding"
              @click="add"
              >Add service partner</el-button
            >
            <el-button v-else type="primary" :loading="updating" @click="update"
              >Save Changes</el-button
            >
          </div>
        </template>
      </eden-form-section>
    </el-form>
  </div>
</template>

<script>
import servicepartners from "@/requests/serviceoperations/servicepartners";

export default {
  name: "ServicePartnerForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    servicePartner: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        avatar: "",
        organization_name: "",
        email: "",
        phone_no: "",
        service: "",
        address: "",
      },
      uploadingPhoto: false,
      adding: false,
      updating: false,
    };
  },
  computed: {
    services() {
      return this.$store.getters.services;
    },
    servicePartnerId() {
      return this.$route.params.id;
    },
  },
  created() {
    if (this.action === "edit") {
      this.loading = true;
      this.form = this.servicePartner;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  },
  methods: {
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        servicepartners
          .add(this.form)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.$router.push({ name: "serviceoperations.partners.index" });
            } else {
              this.$message.error(response.data.message);
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        servicepartners
          .update(this.servicePartnerId, this.form)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.updating = false;
              this.goToSpPage();
            } else {
              this.$message.error(response.data.message);
              this.updating = false;
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    goToSpPage() {
      this.$router.push({
        name: "serviceoperations.partners.partner",
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>
